import { css } from '@emotion/react';

const font = css`
    font-family: 'Cormorant', serif;
    font-weight: 500;
`;

export const h1 = css`
    ${font}
    font-size: 2.25rem;
    line-height: 109%;

    @media (min-width: 1024px) {
        font-size: 3.375rem;
    }

    @media (min-width: 1280px) {
        font-size: 4.5rem;
    }
`;

export const h2 = css`
    ${font}
    font-size: 1.75rem;
    line-height: 122%;
    text-transform: uppercase;

    @media (min-width: 1024px) {
        font-size: 2.25rem;
    }

    @media (min-width: 1280px) {
        font-size: 2.75rem;
    }
`;

export const h3 = css`
    ${font};
    font-size: 1.5rem;
    line-height: 120%;

    @media (min-width: 1024px) {
        font-size: 2rem;
    }

    @media (min-width: 1280px) {
        font-size: 2.5rem;
    }
`;

export const h4 = css`
    ${font}
    font-size: 1.25rem;
    line-height: 120%;

    @media (min-width: 1024px) {
        font-size: 1.625rem;
    }

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h5 = css`
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 150%;
    font-family: 'Manrope', sans-serif;

    @media (min-width: 1024px) {
        font-size: 1.375rem;
    }

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const h6 = css`
    font-size: 1rem;
    font-weight: 800;
    line-height: 150%;
`;

export const smTxt = css`
    font-size: 0.875rem;
`;

export const lgTxt = css`
    font-size: 1.125rem;
`;
